import { ReactElement } from 'react';
import { Route, Switch } from 'react-router-dom';
import PurchasesOverview from '../components/PurchasesOverview/PurchasesOverview';
import PurchaseInformation from '../PurchaseInformation/PurchaseInformation';
import { ShopOverviewView } from '../ShopAtWork';
import useShopAtWorkNavigation from '../useShopAtWorkNavigation';

const MyPurchases = (): ReactElement => {
    const { getOrderLink } = useShopAtWorkNavigation();

    return (
        <Switch>
            <Route path={`/:portalName${getOrderLink(ShopOverviewView.MyPurchases, ':orderId')}`}>
                <PurchaseInformation view={ShopOverviewView.MyPurchases} />
            </Route>
            <Route>
                <PurchasesOverview view={ShopOverviewView.MyPurchases} />
            </Route>
        </Switch>
    );
};

export default MyPurchases;
