import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { sidebarOperations } from '../../common/Sidebar/duck';
import { SidebarMenuItem } from '../../components';
import { useNavigation } from '../../utility';
import { useIsMobile } from '../../utility/hooks/useIsMobile';

const foodForMeetingsLink = '/FoodForMeetings/';

const FoodForMeetingsDynamicMenuItem = (): ReactElement => {
    const { t } = useTranslation('common');

    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    const { pathname } = useLocation();
    const navigation = useNavigation();

    const onNavigate = (url: string, shouldCloseSideBar = true) => {
        if (isMobile && shouldCloseSideBar) dispatch(sidebarOperations.handleHideSidebar());
        navigation.push(url);
    };

    const goToFoodForMeetings = () => onNavigate(foodForMeetingsLink, false);

    return (
        <SidebarMenuItem
            icon="CutleryIcon"
            isActive={pathname?.includes(foodForMeetingsLink)}
            onClick={goToFoodForMeetings}
            text={t('foodForMeetingsMenuLabel')}
        />
    );
};

export default FoodForMeetingsDynamicMenuItem;
