import styled, { css } from 'styled-components';
import { MEDIA_BREAKPOINTS, MEDIA_INDEX } from '../constants';
import {
    BORDER_STYLES,
    FLEX_STYLES,
    GET_STYLE_VALUE,
    INCLUDE_ARRAY_VALUE,
    MARGIN_PADDING_STYLES,
    OVERFLOW_STYLES,
    POSITION_STYLES,
    SIZE_STYLES,
} from '../stylesHelpers';
import { IBoxProps } from './types';

const FlexBoxStyled = styled.div.withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => {
        return defaultValidatorFn(prop);
    },
})<IBoxProps>`
    background: ${({ background }) => background ?? (background && background[MEDIA_INDEX.DESKTOP]) ?? 'transparent'};
    opacity: ${({ opacity }) => opacity ?? 1};
    transform: ${({ transform }) => transform ?? 'none'};
    box-shadow: ${({ boxShadow }) => boxShadow ?? 'none'};
    cursor: ${({ cursor }) => cursor || 'inherit'};
    ${(props) => SIZE_STYLES(props)};
    ${(props) => FLEX_STYLES(props)};
    ${(props) => MARGIN_PADDING_STYLES(props)};
    ${(props) => BORDER_STYLES(props)};
    ${(props) => OVERFLOW_STYLES(props)};
    ${(props) =>
        props.skeleton
            ? css`
                  position: relative;
              `
            : POSITION_STYLES(props)};
    ${(props) =>
        props.hoverBackground &&
        `&:hover {
            background: ${props.hoverBackground};
        }`}

    ${({ skeleton, ...props }) =>
        INCLUDE_ARRAY_VALUE(props) &&
        css`
            @media (max-width: ${MEDIA_BREAKPOINTS.TABLET}px) {
                ${SIZE_STYLES(props, MEDIA_INDEX.TABLET)}
                ${FLEX_STYLES(props, MEDIA_INDEX.TABLET)}
                    ${MARGIN_PADDING_STYLES(props, MEDIA_INDEX.TABLET)}
                    ${BORDER_STYLES(props, MEDIA_INDEX.TABLET)}
                    ${OVERFLOW_STYLES(props, MEDIA_INDEX.TABLET)}
                ${skeleton
                    ? css`
                          position: relative;
                      `
                    : POSITION_STYLES(props)};
            }
            @media (max-width: ${MEDIA_BREAKPOINTS.MOBILE}px) {
                ${SIZE_STYLES(props, MEDIA_INDEX.MOBILE)}
                ${FLEX_STYLES(props, MEDIA_INDEX.MOBILE)}
                    ${MARGIN_PADDING_STYLES(props, MEDIA_INDEX.MOBILE)}
                    ${BORDER_STYLES(props, MEDIA_INDEX.MOBILE)}
                    ${OVERFLOW_STYLES(props, MEDIA_INDEX.MOBILE)}
                ${skeleton
                    ? css`
                          position: relative;
                      `
                    : POSITION_STYLES(props)};
            }
        `}

${(props) =>
        props.skeleton &&
        css`
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 99;
                background-color: #f0f0f0;
                background-image: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
                background-size: 200% 100%;
                animation: shimmer 1.2s infinite linear;
                overflow: hidden;
                border-radius: ${GET_STYLE_VALUE(props.br)};
                @keyframes shimmer {
                    0% {
                        background-position: 100% 0;
                    }
                    100% {
                        background-position: -100% 0;
                    }
                }
            }
        `}
`;

export default FlexBoxStyled;
