import { useState, ReactElement, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { GhostPrimaryButton, Modal, ModalType, PrimaryButton } from '../../../../../../../components';
import RadioButton from '../components/RadioButton';

const ContentContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-grow: 1;
    flex-direction: column;
    padding: 1.7rem;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const Paragraph = styled.p`
    font-size: 1.143rem;
    line-height: 1.714rem;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
    &&& {
        margin-left: 1rem;
    }
`;

interface RemoveCaseworkerModalProps {
    open: boolean;
    onClickNext(options: any): void;
    onClose(): void;
}

const RemoveCaseworkerModal = ({ open, onClose, onClickNext }: RemoveCaseworkerModalProps): ReactElement => {
    const [newTicketAssignee, setNewTicketAssignee] = useState('noNewAssignee');
    const { t: tCommon } = useTranslation('common');
    const { t } = useTranslation('Ticketing', { keyPrefix: 'settings' });
    const onChangeRadioButtonHandler = (e: ChangeEvent<HTMLInputElement>) => setNewTicketAssignee(e.target.value);

    return (
        <Modal
            title={t('removeCaseManager')}
            onClose={() => null}
            open={open}
            type={ModalType.Small}
            hideCloseButton={true}
            bottomContent={
                <ButtonContainer>
                    <GhostPrimaryButton onClick={onClose}>{tCommon('cancelButton')}</GhostPrimaryButton>
                    <StyledPrimaryButton onClick={() => onClickNext(newTicketAssignee)}>
                        {tCommon('next')}
                    </StyledPrimaryButton>
                </ButtonContainer>
            }
        >
            <ContentContainer>
                <Paragraph>{t('whatWillHappenToActiveInquires')}</Paragraph>
                <Paragraph>{t('caseManagerWillBeDeleteForCloseInquires')}</Paragraph>
                <div onChange={onChangeRadioButtonHandler}>
                    <RadioButton
                        name="removeCaseworker"
                        value="noNewAssignee"
                        checked={newTicketAssignee === 'noNewAssignee'}
                    >
                        {t('caseManagerWillBeDelete')}
                    </RadioButton>
                    <RadioButton
                        name="removeCaseworker"
                        value="newAssignee"
                        checked={newTicketAssignee === 'newAssignee'}
                    >
                        {t('assignToAnotherCaseManager')}
                    </RadioButton>
                </div>
            </ContentContainer>
        </Modal>
    );
};

export default RemoveCaseworkerModal;
