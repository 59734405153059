import { ReactElement } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import type { TFunction } from 'react-i18next';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as yup from 'yup';
import { Category, TicketType } from '../../../../../../api/apiTypes/ticketingApiTypes';
import { InputField, PrimaryButton, SvgIcon } from '../../../../../../components';
import {
    useGetTicketsCountForCategory,
    useUpdateTicketingTypeCategory,
} from '../../../../apiQueries/useTicketingTypeCategory';
import { Header, HorizontalRule } from '../../../../common/styles';
import { DeleteCategoryModal, CategoryDuplicate, CategoryPublish } from './components/index';

export const Form = styled.form`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: flex-end;
`;

const CategoryInputField = styled(InputField)`
    margin-right: 0.571rem;
`;

type SaveButtonProps = { inputFieldHasError: boolean };
const SaveButton = styled(PrimaryButton)`
    &&& {
        height: 2.685rem;
        margin-top: ${({ inputFieldHasError }: SaveButtonProps) => (inputFieldHasError ? '0' : '0.571rem')};
        align-self: ${({ inputFieldHasError }: SaveButtonProps) => inputFieldHasError && 'center'};
    }
`;

const SaveButtonText = styled.span`
    padding-right: 0.667rem;
`;

type Form = { name: string };

const schema = (t: TFunction<'Ticketing', 'settings'>) => {
    return yup.object().shape({
        name: yup
            .string()
            .required(t('errorMessageNameIsRequired'))
            .min(2, t('errorMessageNameMustHaveMinValue'))
            .max(100, t('errorMessageNameMustHaveMaxValue'))
            .test(`unique-category`, t('errorMessageNameIsDublicateValue'), function (value, { options: { context } }) {
                return value !== undefined && context?.existingCategoryNamesWithoutCurrentLowerCased.length
                    ? !context?.existingCategoryNamesWithoutCurrentLowerCased.includes(value.toString().toLowerCase())
                    : true;
            }),
    });
};

interface GeneralTabProps {
    category: Category;
    typeId: string;
    existingTicketTypeCategoryNames: string[];
    changesSaved(isSaved: boolean): void;
    allTypes: TicketType[];
}
const GeneralTab = ({
    category,
    typeId,
    existingTicketTypeCategoryNames,
    changesSaved,
    allTypes,
}: GeneralTabProps): ReactElement => {
    const existingCategoryNamesWithoutCurrentLowerCased = existingTicketTypeCategoryNames
        .filter((categoryName: string) => {
            return categoryName !== category.name;
        })
        .map((categoryName) => categoryName.toLowerCase());

    const { data: ticketCountClosed } = useGetTicketsCountForCategory(typeId, category.id, true);
    const { data: ticketCountOpen } = useGetTicketsCountForCategory(typeId, category.id);
    const { t: tCommon } = useTranslation('common');
    const { t } = useTranslation('Ticketing', { keyPrefix: 'settings' });
    const formId = 'EditCategory' + category.id;
    const [updateCategory, { isLoading, isSuccess }] = useUpdateTicketingTypeCategory();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<Form>({
        mode: 'onTouched',
        resolver: yupResolver(schema(t)),
        defaultValues: { name: category.name },
        context: {
            existingCategoryNamesWithoutCurrentLowerCased,
        },
    });

    const onSubmit = (data: Form) => {
        updateCategory(
            [
                typeId,
                category.id,
                {
                    name: data.name,
                },
            ],
            { onSuccess: () => changesSaved(true) },
        );
    };

    return (
        <>
            <Header>{t('whatNameOfThisCategory')}</Header>
            <Form
                id={formId}
                onSubmit={handleSubmit(onSubmit)}
                onChange={() => changesSaved(watch('name') === category.name)}
            >
                <CategoryInputField
                    label={tCommon('categoryName')}
                    {...register('name')}
                    placeholder={tCommon('categoryName')}
                    required
                    error={errors.name?.message}
                />
                <SaveButton
                    type="submit"
                    form={formId}
                    disabled={watch('name') === category.name || Object.keys(errors).length > 0}
                    inputFieldHasError={errors.name?.message}
                >
                    <>
                        <SaveButtonText>
                            {isLoading ? tCommon('storing') : isSuccess ? t('saved') : tCommon('saveButton')}
                        </SaveButtonText>
                        <SvgIcon name="CheckIcon" />
                    </>
                </SaveButton>
            </Form>
            <HorizontalRule />
            <CategoryPublish categoryId={category.id} typeId={typeId} isPublished={category.isPublished} />
            <HorizontalRule />
            <CategoryDuplicate
                category={category}
                existingTicketTypeCategoryNames={existingTicketTypeCategoryNames}
                typeId={typeId}
            />
            <HorizontalRule />
            <DeleteCategoryModal
                category={category}
                typeId={typeId}
                disableDeleteCategory={existingTicketTypeCategoryNames.length <= 1}
                allTypes={allTypes}
                ticketCountOpen={ticketCountOpen ?? 0}
                ticketCountClosed={ticketCountClosed ?? 0}
            />
        </>
    );
};

export default GeneralTab;
