import { AxiosError, AxiosResponse } from 'axios';
import { MutationResultPair, useMutation, useQueryCache } from 'react-query';
import { VendorOrderStatusCode, VendorOrderStatusOption } from '../../../../api/apiTypes/shopApiTypes';
import { updateOrderStatus } from '../../../../api/shopApi';
import { OrderCacheKey } from '../../../ShopAtWork/apiQueries/useShopOrders';
import { AllUsersOrdersIPortalsCacheKey } from './useGetAllUsersOrdersInAllPortals';

const getStatus = (status: string) =>
    ({
        [VendorOrderStatusOption.Canceled]: VendorOrderStatusCode.Canceled,
        [VendorOrderStatusOption.Ordered]: VendorOrderStatusCode.Ordered,
        [VendorOrderStatusOption.Delivered]: VendorOrderStatusCode.Delivered,
    }[status] || VendorOrderStatusCode.Ordered);

export const useUpdateOrderStatus = (): MutationResultPair<
    AxiosResponse<unknown>,
    string | AxiosError<string> | Error,
    [string[], string],
    never
> => {
    const cache = useQueryCache();
    return useMutation(
        ([arrayOfOrderIds, status]) => {
            return updateOrderStatus(arrayOfOrderIds, getStatus(status));
        },
        {
            onSuccess: () => {
                cache.invalidateQueries(OrderCacheKey);
            },
        },
    );
};

export const useUpdateOrdersStatuses = (): MutationResultPair<
    AxiosResponse<unknown>,
    string | AxiosError<string> | Error,
    [string[], string],
    never
> => {
    const cache = useQueryCache();
    return useMutation(
        ([arrayOfOrderIds, status]) => {
            return updateOrderStatus(arrayOfOrderIds, getStatus(status));
        },
        {
            onSuccess: () => {
                cache.refetchQueries(AllUsersOrdersIPortalsCacheKey);
            },
        },
    );
};
