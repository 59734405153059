import { useRef, ReactElement, SyntheticEvent } from 'react';
import type { CSSObjectWithLabel, SingleValue, GroupBase, ClearIndicatorProps, PropsValue } from 'react-select';
import Select, { components } from 'react-select';
import styled from 'styled-components';
import { SvgIcon } from '../..';
import FieldErrorMessage from '../../FieldErrorMessage';

const Wrapper = styled.div`
    overflow-wrap: break-word;
`;

type LabelProps = { disable?: boolean; error?: boolean };
const Label = styled.label`
    display: inline-block;
    color: ${({ disable, error }: LabelProps) => {
        if (disable) {
            return 'var(--text-disabled-color)';
        }
        if (error) {
            return 'var(--error-color)';
        }
        return 'var(--text-placeholder-color)';
    }};
    font-size: 0.857rem;
`;

const IconContainer = styled.div`
    display: flex;
    padding-right: 0.5rem;

    svg {
        path {
            fill: #475156;
        }
    }
`;

const customStyles = {
    option: (provided: CSSObjectWithLabel, state: { isFocused: boolean; isSelected: boolean; selectProps: any }) => ({
        ...provided,
        color: state.isFocused || state.isSelected ? 'var(--primary-on-color)' : '#475156',
        border: 'none',
        backgroundColor: state.isFocused || state.isSelected ? 'var(--primary-color-light)' : 'primary-on-color-light',

        '&:hover': {
            backgroundColor: 'var(--primary-color-light)',
            color: 'var(--primary-on-color-light)',
        },
    }),
    control: (provided: CSSObjectWithLabel, state: { isFocused: boolean; selectProps: any }) => {
        return {
            minWidth: 75,
            border: '1px solid',
            borderColor: state.selectProps.error ? 'var(--error-color)' : ' #D4D6D7',
            borderRadius: '0.25rem',
            cursor: 'text',
            display: 'flex',
            outline: state.isFocused ? '1px solid var(--primary-color)' : 'none',
        };
    },
    clearIndicator: (
        provided: CSSObjectWithLabel,
        state: ClearIndicatorProps<OptionType, false, GroupBase<OptionType>>,
    ) => ({
        ...provided,
        cursor: 'pointer',
        color: state.selectProps.inputValue.length > 0 ? '#677074 !important' : 'lightgrey',
        display: 'flex',
    }),
    menu: (provided: CSSObjectWithLabel) => ({
        ...provided,
    }),
    menuList: (provided: CSSObjectWithLabel) => ({
        ...provided,
        maxHeight: '11.5rem',
    }),
    valueContainer: (provided: any) => ({
        ...provided,
        display: 'flex',
        overflow: 'hidden',
        flexWrap: 'none',
    }),
    input: (provided: CSSObjectWithLabel) => ({
        ...provided,
        input: {
            opacity: '1 !important',
        },
    }),
    placeholder: (provided: CSSObjectWithLabel, state: { isDisabled: boolean }) => ({
        ...provided,
        color: state.isDisabled ? 'var(--text-disabled-color)' : 'var(--text-placeholder-color)',
    }),
    dropdownIndicator: (provided: CSSObjectWithLabel) => ({
        ...provided,
        cursor: 'pointer',
    }),
    singleValue: (provided: CSSObjectWithLabel) => {
        const opacity = 1;
        const transition = 'opacity 300ms';

        return { ...provided, opacity, transition };
    },
};

const CustomValueContainer = ({ children, ...props }: any): ReactElement => {
    return (
        <components.ValueContainer {...props}>
            <IconContainer>
                <SvgIcon name="Search" />
            </IconContainer>
            {children}
        </components.ValueContainer>
    );
};

export type OptionType = {
    label: string;
};

export type OptionTypeWithId = {
    id: string;
    label: string;
} | null;

interface InputSelectProps {
    name: string;
    initialValue?: OptionTypeWithId;
    value?: PropsValue<OptionType> | undefined;
    label?: string;
    isClearable?: boolean;
    onChange(selectedOption: SingleValue<OptionTypeWithId>): void;
    id: string;
    options: OptionType[];
    error?: string;
    noOptionsMessage?: string;
    placeholder?: string;
    isLoading?: boolean;
    className?: string;
    showSearchIcon?: boolean;
    disable?: boolean;
    loadingMessage?: string;
    onFocus?: (e: SyntheticEvent) => void;
    onMenuScrollToBottom?: () => void;
    onKeyDown?: (e: SyntheticEvent) => void;
}

const InputSelect = ({
    name = '',
    initialValue,
    value,
    label,
    isClearable,
    onChange,
    id,
    options,
    error,
    noOptionsMessage,
    placeholder,
    isLoading,
    className,
    showSearchIcon,
    disable = false,
    loadingMessage,
    onFocus,
    onMenuScrollToBottom,
    onKeyDown,
    ...rest
}: InputSelectProps): JSX.Element => {
    const elementRef = useRef(null);

    return (
        <Wrapper ref={elementRef} className={className}>
            {label && (
                <Label disable={disable} error={!!error}>
                    {label}
                </Label>
            )}
            <Select
                inputId={id}
                name={name}
                isClearable={isClearable}
                onChange={(e: SingleValue<{ label: string }>) => onChange(e as SingleValue<OptionTypeWithId>)}
                options={options}
                styles={customStyles}
                {...{
                    error,
                }}
                noOptionsMessage={() => {
                    return noOptionsMessage ?? null;
                }}
                placeholder={placeholder}
                blurInputOnSelect={false}
                isLoading={isLoading}
                defaultValue={initialValue?.label ? { label: initialValue.label } : undefined}
                components={showSearchIcon ? { ValueContainer: CustomValueContainer } : undefined}
                value={value}
                getOptionValue={(option) => option.label}
                isDisabled={disable}
                loadingMessage={() => loadingMessage}
                onFocus={onFocus}
                onMenuScrollToBottom={onMenuScrollToBottom}
                onKeyDown={onKeyDown}
                {...rest}
            />
            {error && <FieldErrorMessage>{error}</FieldErrorMessage>}
        </Wrapper>
    );
};

export default InputSelect;
