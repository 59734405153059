import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { sidebarOperations } from '../../common/Sidebar/duck';
import { useNavigation } from '../../utility';
import { useIsMobile } from '../../utility/hooks/useIsMobile';
import { ShopOverviewView } from './ShopAtWork';

interface UseShopAtWorkNavigationReturnType {
    companyTransactionsLink: string;
    myPurchasesLink: string;
    portalTransactionsLink: string;
    settingsLink: string;
    inventoryLink: string;
    inventoryAddLink: string;
    inventoryEditLink: string;
    inventoryCategoryLink: string;
    goToShopAtWork(): void;
    goToCompanyTransactions(): void;
    goToMyPurchases(): void;
    goToPortalTransactions(): void;
    goToSettings(): void;
    goToInventory(): void;
    goToAddNewProduct(): void;
    goToEditProduct(id: string): void;
    goToInventoryCategory(): void;
    getOrderLink(view: ShopOverviewView, orderId: string): string;
    getModuleName(): string;
    goToOrderLink(view: ShopOverviewView, orderId: string): void;
    goToInvoice(view: ShopOverviewView, orderId: string): void;
}

const useShopAtWorkNavigation = (): UseShopAtWorkNavigationReturnType => {
    const navigation = useNavigation();
    const dispatch = useDispatch();
    const isMobile = useIsMobile();

    return useMemo(() => {
        const moduleName = 'shopAtWork';
        const companyTransactionsLink = '/shopAtWork/companyTransaction';
        const myPurchasesLink = '/shopAtWork/myPurchases';
        const portalTransactionsLink = '/shopAtWork/portalTransaction';
        const settingsLink = '/shopAtWork/settings';
        const inventoryLink = '/shopAtWork/inventory';
        const inventoryAddLink = '/shopAtWork/inventory/add';
        const inventoryEditLink = '/shopAtWork/inventory/edit';
        const inventoryCategoryLink = '/shopAtWork/inventory/category';
        const getOrderLink = (view: ShopOverviewView, orderId: string) => {
            let link = myPurchasesLink;
            if (view === ShopOverviewView.CompanyPurchases) {
                link = companyTransactionsLink;
            }
            if (view === ShopOverviewView.PortalPurchases) {
                link = portalTransactionsLink;
            }
            return link + `/${orderId}`;
        };
        const onNavigate = (url: string, shouldCloseSideBar = true) => {
            if (isMobile && shouldCloseSideBar) dispatch(sidebarOperations.handleHideSidebar());
            navigation.push(url);
        };

        return {
            companyTransactionsLink,
            myPurchasesLink,
            portalTransactionsLink,
            settingsLink,
            inventoryLink,
            inventoryAddLink,
            inventoryEditLink,
            inventoryCategoryLink,
            goToShopAtWork: () => onNavigate(myPurchasesLink, false),
            goToCompanyTransactions: () => onNavigate(companyTransactionsLink),
            goToMyPurchases: () => onNavigate(myPurchasesLink),
            goToPortalTransactions: () => onNavigate(portalTransactionsLink),
            goToSettings: () => onNavigate(settingsLink),
            goToInventory: () => onNavigate(inventoryLink),
            goToAddNewProduct: () => onNavigate(inventoryAddLink),
            goToEditProduct: (id: string) => onNavigate(`${inventoryEditLink}/${id}`),
            goToInventoryCategory: () => onNavigate(inventoryCategoryLink),
            getOrderLink,
            getModuleName: () => moduleName,
            goToOrderLink: (view: ShopOverviewView, orderId: string) => onNavigate(getOrderLink(view, orderId)),
            goToInvoice: (view: ShopOverviewView, orderId: string) =>
                onNavigate(`${getOrderLink(view, orderId)}/invoice`),
        };
    }, [navigation]);
};

export default useShopAtWorkNavigation;
