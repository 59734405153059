import styled from 'styled-components';
import { MARGIN_PADDING_STYLES } from '../stylesHelpers';
import { IStressedTextProps } from './types';

const StressedTextStyled = styled.span.withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => defaultValidatorFn(prop),
})<IStressedTextProps>`
    font-size: ${({ fontSize }) => fontSize ?? 'inherit'};
    color: ${({ color }) => color ?? 'inherit'};
    text-align: ${({ textAlign }) => textAlign ?? 'left'};
    line-height: ${({ lineHeight }) => lineHeight ?? 'normal'};
    letter-spacing: ${({ letterSpacing }) => letterSpacing ?? 'normal'};
    font-style: ${({ fontStyle }) => fontStyle ?? 'normal'};
    font-weight: ${({ fontWeight }) => fontWeight ?? 'normal'};
    text-decoration: ${({ textDecoration }) => textDecoration ?? 'none'};
    text-wrap: ${({ textWrap }) => textWrap ?? 'inherit'};
    ${(props) => MARGIN_PADDING_STYLES(props)};
`;

export default StressedTextStyled;
