import { Perm } from '../../common';

export type Role = { name: Perm; phrase: string };

export interface CreateMultipleUsersDto {
    emails: string[];
    roles: string[];
    accesses: string[];
}

export interface UpdateUser {
    firstName: string;
    lastName: string;
    name: string;
    email: string;
    phone: string;
    activeFrom: string;
    activeTo: string;
    roles: string[];
    status: string;
    guid: string;
    language: string;
}

export interface User {
    accessibleRoles: Role[];
    activeFrom: string;
    activeTo: string;
    email: string;
    firstName: string;
    guid: string;
    lastName: string;
    name: string;
    promptSetWifiAtWorkPassword: boolean;
    phone: string;
    roles: Role[];
    type: string;
    status: UserStatus;
    language: string;
    visitationAvailability: {
        companyVisitationEnabled: boolean;
        userVisitationEnabled: boolean;
        allowVisitationChange: boolean;
    };
    vehicles: VehicleDto[];
    portals: PortalDto[];
}

export interface OwnUser {
    accessibleRoles: Role[];
    activeFrom: string;
    activeTo: string;
    confirmedDate: string;
    email: string;
    firstName?: string;
    id: string;
    lastName: string;
    name: string;
    promptSetWifiAtWorkPassword: boolean;
    phoneNumber: string;
    roles: Role[];
    type: string;
    language: string;
    status: UserStatus;
    avatar: string | null;
    portalId: string;
    portalName: string;
    companyId: string;
    companyName: string;
    buildingId: string;
    floorId: string;
    vehicles: VehicleDto[];
    portals: PortalDto[];
    visitationAvailability: {
        userVisitationEnabled: boolean;
        companyVisitationEnabled: boolean;
    };
}

export interface Users {
    email: string | null;
    guid: string;
    isConfirmed: boolean;
    name: string;
    phone: string | null;
    roles: string[];
    status: UserStatus;
    types: Perm;
    accessZoneGroups: accessZone[] | null;
    type: string;
}

interface accessZone {
    id: string;
    name: string;
}

export interface CompanyAdmin {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
}

export interface Domain {
    domain: string;
}

export interface Profile {
    name?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    language?: string;
}

export interface CreateCompanyInterface {
    companyInfo: CreateCompanyBasicInfoDto;
    companyTenancyInfo: TenancyInfo;
    contactPerson: CompanyContactPerson;
    isInviteContactPerson: boolean;
    accessGroups: string[];
}

export interface Pincode {
    pincode: string;
}

/**
 * @deprecated
 */
export interface TenancyInfo {
    propertyGuid: string | null;
    buildingGuid: string | null;
    floorGuid: string | null;
    tenancyStart: string | null;
    tenancyEnd: string | null;
    contractBlobGuid: string | null;
}

/**
 * @deprecated
 */
export interface UpdateTenancyInfo {
    propertyGuid?: string | null;
    buildingGuid?: string | null;
    floorGuid?: string | null;
    tenancyStart?: string | null;
    tenancyEnd?: string | null;
    contractBlobGuid?: string | null;
}

export interface PrivacyPolicyStatus {
    guid: string;
    portalId: string;
    statuses: {
        status: 'Approved' | 'Rejected';
        statusDateTime: string;
    }[];
    text: string;
    textType: string;
    url: string;
    version: string;
    versionDateTime: string;
}

export interface CompanySearchModel {
    companyGuid: string;
    companyName: string;
    companyStatus: CompanyType;
    contactPersonName: string;
    contactPersonPhone: string;
    contactPersonEmail: string;
    propertyName: string;
    buildingId: string;
    buildingName: string;
    floorId: string;
    floorName: string;
    tenancyStartDate: string;
    tenancyEndDate: string;
    countActiveUsers: number;
    isWiFiActivated: boolean;
    isCantinePassActivated: boolean;
    updated: string;
}

/**
 * @deprecated
 */
export interface CompanyBasicInfo {
    companyName: string;
    companyOrgNumber: string | null;
    companyAddressStreet: string | null;
    companyAddressZipCode: string | null;
    companyAddressCity: string | null;
    companyBillingCity: string | null;
    companyBillingZipCode: string | null;
    companyBillingAddress: string | null;
    companyType: CompanyType | null;
    companyGuid: string;
    companyStatus: CompanyType;
    visitationEnabled: boolean;
}

/**
 * @deprecated
 */
export type CreateCompanyBasicInfoDto = Omit<CompanyBasicInfo, 'companyGuid' | 'companyStatus'>;

export enum CompanyType {
    Tenant = 'Leietaker',
    Supplier = 'Leverandør',
    Inactive = 'Inactive',
}

/**
 * @deprecated
 */
export interface UpdateCompanyBasicInfo {
    companyName: string;
    companyOrgNumber?: string | null;
    companyAddressStreet?: string | null;
    companyAddressZipCode?: string | null;
    companyAddressCity?: string | null;
    companyBillingCity?: string | null;
    companyBillingZipCode?: string | null;
    companyBillingAddress?: string | null;
    companyType?: string;
    contactPerson?: CompanyContactPerson;
}

export interface WifiAtWorkPassword {
    password: string;
}

/**
 * @deprecated
 */
export interface CompanyContactPerson {
    contactPersonFirstName?: string | null;
    contactPersonLastName?: string | null;
    contactPersonPhone?: string | null;
    contactPersonEmail?: string | null;
}

export enum PolicyType {
    Portal = 'portal',
    Wifi = 'wifi',
}

export type UniqueGuid = string;
export type DateISO = string;

export interface CompanyInfo {
    companyName: string;
    companyOrgNumber: string | null;
    companyAddressStreet: string | null;
    companyAddressZipCode: string | null;
    companyAddressCity: string | null;
    companyBillingCity?: string | null;
    companyBillingZipCode?: string | null;
    companyBillingAddress?: string | null;
    companyType: string;
}

export interface CompanyTenancyInfo {
    propertyGuid: UniqueGuid | null;
    buildingGuid: UniqueGuid | null;
    floorGuid: UniqueGuid | null;
    tenancyStart: DateISO | null;
    tenancyEnd: DateISO | null;
    contractBlobGuid: UniqueGuid | null;
}

export interface ContactPerson {
    contactPersonFirstName: string | null;
    contactPersonLastName: string | null;
    contactPersonEmail: string | null;
    contactPersonPhone: string | null;
}

export interface Company {
    guid: string;
    name: string;
    status: CompanyType;
    visitationEnabled: boolean;
}

/**
 *TODO - That field is not exists in API, but must be.
 */
export interface AdminPerson {
    contactPersonFirstName: string | null;
    contactPersonLastName: string | null;
    contactPersonEmail: string;
    contactPersonPhone: string | null;
}

export interface CreateNewTenantDTO {
    companyInfo: CompanyInfo;
    companyTenancyInfo: CompanyTenancyInfo;
    contactPerson: ContactPerson;
    companyAdmin: AdminPerson;
    isInviteContactPerson: boolean;
    accessGroups: UniqueGuid[];
}

export enum VehicleFuelType {
    Fuel = 0,
    Electric = 10,
    Hybrid = 20,
}

export enum VehicleType {
    Car = 0,
    Motorcycle = 10,
}

export interface VehicleDto {
    id: string;
    licensePlate: string;
    fuelType: VehicleFuelType;
    type: VehicleType;
}

export type VehicleModel = Omit<VehicleDto, 'id'>;

export enum ModuleName {
    Meeting = 'Meeting',
    Food = 'Food',
    FoodForMeetings = 'FoodForMeetings',
    HelpArticles = 'HelpArticles',
    Articles = 'Articles',
    DailyMenu = 'DailyMenu',
    Ticketing = 'Ticketing',
    ShopAtWork = 'ShopAtWork',
    WifiAtWork = 'WifiAtWork',
    AccessControl = 'AccessControl',
    FoodExternal = 'FoodExternal',
    TicketingExternal = 'TicketingExternal',
    Dashboard = 'Dashboard',
    MeetingHouseOfOslo = 'MeetingHouseOfOslo',
    Visitation = 'Visitation',
    Parking = 'Parking',
    Lunch = 'Lunch',
    IntegrationModule = 'IntegrationModule',
    Wardrobe = 'Wardrobe',
    GoldByOpen = 'GoldByOpen',
    Booking = 'Booking',
    Event = 'Event',
    HealthAndFitness = 'HealthAndFitness',
    Cart = 'Cart',
    EmailFoodModule = 'EmailFoodModule',
}

export interface Module {
    name: ModuleName;
    children: Module[];
    titleGuid?: string;
    phrases?: Array<unknown>;
    id: string;
}

export interface RolePhrase {
    name: UserRole;
    phrase: string | null;
}

export enum UserRole {
    CompanyAdministrator = 'company-administrator',
    ArticleEditor = 'article-editor',
    Receptionist = 'receptionist',
    PortalAdministrator = 'portal-administrator',
    Kitchen = 'kitchen',
    FoodRequirent = 'food-requirent',
    User = 'user',
    AccessController = 'access-controller',
    HealthAdministrator = 'health-administrator',
}

export interface Menu {
    children: Menu[] | null;
    icon: string | null;
    iconType: string;
    name: string;
    phraseGuid: string;
    value: string;
}

export interface AppContext {
    menu: Menu[];
    modules: Module[];
    perms: string[];
    roles: string[];
    services: string[];
}

export enum UserStatus {
    Inactive = 'Inactive',
    Invited = 'Invited',
    Active = 'Active',
    DeletedBySelf = 'DeletedBySelf',
    DeletedByOther = 'DeletedByOther',
}

export interface PortalDto {
    name: string;
    id: number;
    activeFrom: string | null;
    activeTo: string | null;
    address: string;
    alias: string;
    frontPageImage: string;
    isDefault: boolean;
    isSelected: boolean;
}

export type GenerateQRCodeData = {
    location: string | null;
    guestRegistrationKey: string | null;
    expiresAfter: string | null;
};

export interface ActivateDoorOpening {
    lockID: string;
    accessSystemId: string;
}
