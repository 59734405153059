import { ReactElement, useState, RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { TicketType } from '../../../../../../api/apiTypes/ticketingApiTypes';
import { Header, Paragraph, ChangesSaved } from '../../../../common/styles';
import { CaseworkerSearch } from './components/index';
import Modals from './Modals/index';

const Information = styled(Paragraph)`
    padding: 1rem 0 1.714rem;
    margin: 0;
`;

interface CaseworkersTabProps {
    ticketType: TicketType;
    scrollRef: RefObject<HTMLElement>;
}

const CaseworkersTab = ({ ticketType, scrollRef }: CaseworkersTabProps): ReactElement => {
    const { t } = useTranslation('Ticketing', { keyPrefix: 'settings' });
    const [openRemoveCaseworkerModal, setOpenRemoveCaseworkerModal] = useState(false);
    const [status, setStatus] = useState(t('savingStatus.noChanges'));
    const [removeCaseworkerGuid, setRemoveCaseworkerGuid] = useState<string>('');

    const onChangeStatusHandler = (status: any) => {
        setStatus(status);
    };

    const onRemoveCaseworkerHandler = (guid: string) => {
        setRemoveCaseworkerGuid(guid);
        setOpenRemoveCaseworkerModal(true);
    };

    return (
        <>
            <Header>{t('caseworkersTabHeader')}</Header>
            <Information>{t('caseworkersTabInformation')}</Information>
            <ChangesSaved>{status}</ChangesSaved>
            <CaseworkerSearch
                onClickRemoveCaseworker={onRemoveCaseworkerHandler}
                caseworkers={ticketType.caseWorkers}
                type="search"
                typedId={ticketType.id}
                onStatusChange={onChangeStatusHandler}
                scrollRef={scrollRef}
            />
            <Modals
                open={openRemoveCaseworkerModal}
                onClose={() => setOpenRemoveCaseworkerModal(false)}
                caseworkerGuid={removeCaseworkerGuid}
                typeId={ticketType.id}
                caseworkers={ticketType.caseWorkers}
            />
        </>
    );
};

export default CaseworkersTab;
