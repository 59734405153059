import { useContext } from 'react';
import { NotificationCategory, NotificationDto } from '../../../api/apiTypes/msgApiTypes';
import logService from '../../../api/infrastructure/logService';
import useArticlesNavigation from '../../../modules/Articles/useArticlesNavigation';
import useEventsNavigation from '../../../modules/Booking/useEventsNavigation';
import useMeetingNavigation from '../../../modules/Meeting/useMeetingNavigation';
import { useUserIsCaseWorker } from '../../../modules/Ticketing/apiQueries/useTicketingUser';
import useTicketingNavigation from '../../../modules/Ticketing/useTicketingNavigation';
import { SidesheetContext, useNavigation, useUserHasPerm } from '../../../utility';
import { SidesheetActionNames } from '../../../utility/Sidesheet/context/types';
import MyProfile from '../../MyProfile';
import { ProfileTabs } from '../../MyProfile/MyProfile';
import Perm from '../../perms';
import { useNotification } from '../context';

const useNotificationNavigation = (): {
    onNavigationHandler: (notification: NotificationDto) => void;
} => {
    const canEditTypes = useUserHasPerm(Perm.TicketingTypesUpdate);
    const { data: caseworkerCheck } = useUserIsCaseWorker(!canEditTypes);
    const isUserCaseworkerOrCanEditTypes = (canEditTypes || caseworkerCheck?.isCaseWorker) ?? false;
    const { dispatch } = useContext(SidesheetContext);
    const { articlesLink } = useArticlesNavigation();
    const { workListLink, userTicketsLink } = useTicketingNavigation();
    const navigation = useNavigation();
    const { approvalRequestsLink } = useMeetingNavigation();
    const { updateSeenNotifications } = useNotification();
    const { userEventsSessionLink } = useEventsNavigation();

    const onNavigationHandler = (notification: NotificationDto) => {
        if (notification.status === 'Sent' || notification.status === 'Seen') {
            updateSeenNotifications(notification);
        }

        if (notification.moduleId) {
            switch (notification.moduleId) {
                case NotificationCategory.CMS: {
                    if (notification.linkedItemId) {
                        navigation.push(`${articlesLink}/${notification.linkedItemId}`);
                    }
                    break;
                }
                case NotificationCategory.Ticketing: {
                    if (notification.linkedItemId) {
                        navigation.push(
                            `${isUserCaseworkerOrCanEditTypes ? workListLink : userTicketsLink}/${
                                notification.linkedItemId
                            }/comments`,
                        );
                    }
                    break;
                }
                case NotificationCategory.WifiAtWork: {
                    dispatch({
                        type: SidesheetActionNames.OpenSidesheet,
                        payload: {
                            component: <MyProfile defaultTab={ProfileTabs.Password} />,
                            hideCloseIcon: true,
                        },
                    });
                    break;
                }
                case NotificationCategory.MeetingApprovalRequests: {
                    navigation.push(`${approvalRequestsLink}`);
                    break;
                }
                case NotificationCategory.HealthAndFitness: {
                    if (notification.linkedItemId) {
                        navigation.push(`${userEventsSessionLink}/${notification.linkedItemId}`);
                    }
                    break;
                }

                default: {
                    logService.log(
                        new Error(
                            `Notification navigation - Received moduleId:${module.id} is not handled by application. In-app navigation and push notification navigation are not handled`,
                        ),
                    );
                    break;
                }
            }
        } else {
            logService.log(
                new Error(
                    `Notification navigation - notification.moduleId or notification.linkedItemId are missing. Notification: ${notification}}`,
                ),
            );
        }
    };

    return { onNavigationHandler };
};

export default useNotificationNavigation;
