import { PropsWithChildren, ReactElement } from 'react';
import styled from 'styled-components';
import { SvgIcon } from '../../components';

const Container = styled.div`
    position: absolute;
    right: 2rem;
    top: 5.7rem;
    border-radius: 0.857rem;
    max-width: 26.286rem;
    height: auto;
    z-index: 1000;
    background-color: #fff;
    box-shadow: 0 0 3.571rem rgba(0, 0, 0, 0.15);
`;

const Caret = styled.div`
    margin-top: -0.571rem;
    position: absolute;
    box-sizing: content-box;
    border: 0.571rem solid transparent;
    top: 0;
    border-top: none;
    border-bottom-color: #fff;
    right: 1.143rem;
    height: 0;
    width: 0.071rem;
`;

const Content = styled.div`
    margin: 1.571rem;
`;

const CloseButton = styled.button`
    margin-left: auto;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    & path {
        fill: var(--text-high-emphasis-color);
    }

    :focus-visible,
    :focus {
        outline: 0.071rem solid var(--primary-color);
        outline-offset: 0.143rem;
    }

    :focus:not(:focus-visible) {
        outline: none;
        outline-offset: 0.143rem;
    }
`;

interface TooltipProps {
    open: boolean;
    onClose(): void;
    hideCloseButton?: boolean;
}

const Tooltip = ({
    children,
    open,
    onClose,
    hideCloseButton,
}: PropsWithChildren<TooltipProps>): ReactElement | null => {
    return open ? (
        <Container>
            <Caret />
            <Content>
                {!hideCloseButton && (
                    <CloseButton onClick={onClose}>
                        <SvgIcon name="CloseIcon" />
                    </CloseButton>
                )}
                {children}
            </Content>
        </Container>
    ) : null;
};

export default Tooltip;
