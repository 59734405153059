import { AxiosResponse, AxiosError } from 'axios';
import { MutationResultPair, useQueryCache, useMutation } from 'react-query';
import {
    updateCompanyVisitationStatus,
    updateUserThemselvesVisitationStatus,
    updateUserVisitationStatus,
} from '../../../../api/portalApi';
import { useGetUserByIdInfoCacheKey } from '../../../../common/MyProfile/apiQueries/useGetUserByIdInfo';
import { CompanyBasicInfoCacheKey } from '../../TenantsAdmin/apiQueries/useTenantsAdmin';
import { orgCacheKey } from '../components/UserAdministrationSidesheet/ApiQueries/useContactInfo';
import { UsersAdminCompaniesCacheKey } from './useUsersAdminCompanies';

const VisitorRegistrationCacheKey = 'VisitorRegistrationCacheKey';

export const useUpdateCompanyVisitorRegistrationService = (): MutationResultPair<
    AxiosResponse<void>,
    Error | AxiosError<string>,
    { isEnabled: boolean; companyId: string },
    never
> => {
    const cache = useQueryCache();
    return useMutation(
        async ({ isEnabled, companyId }) => {
            return await updateCompanyVisitationStatus(isEnabled, companyId);
        },
        {
            onSuccess: (_) => {
                cache.invalidateQueries(VisitorRegistrationCacheKey);
                cache.invalidateQueries(orgCacheKey);
                cache.invalidateQueries(UsersAdminCompaniesCacheKey);
                cache.invalidateQueries(CompanyBasicInfoCacheKey);
            },
        },
    );
};

export const useUpdateUserVisitationStatus = (): MutationResultPair<
    AxiosResponse<void>,
    Error | AxiosError<string>,
    { isEnabled: boolean; userId: string },
    never
> => {
    const cache = useQueryCache();
    return useMutation(
        async ({ isEnabled, userId }) => {
            return await updateUserVisitationStatus(isEnabled, userId);
        },
        {
            onSuccess: (_) => {
                cache.invalidateQueries([orgCacheKey]);
                cache.invalidateQueries([useGetUserByIdInfoCacheKey]);
            },
        },
    );
};

export const useUpdateUserThemselvesVisitationStatus = (): MutationResultPair<
    AxiosResponse<void>,
    Error | AxiosError<string>,
    { isEnabled: boolean },
    never
> => {
    const cache = useQueryCache();
    return useMutation(
        async ({ isEnabled }) => {
            return await updateUserThemselvesVisitationStatus(isEnabled);
        },
        {
            onSuccess: (_) => {
                cache.invalidateQueries([orgCacheKey]);
                cache.invalidateQueries([useGetUserByIdInfoCacheKey]);
            },
        },
    );
};
