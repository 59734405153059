import { AxiosError, AxiosResponse } from 'axios';
import { addDays, startOfWeek } from 'date-fns';
import { MutationResultPair, QueryResult, useMutation, useQuery, useQueryCache } from 'react-query';
import { ImageModel } from '../../../api/apiTypes/cmsApiTypes';
import { DailyMenuUpdateType, DailyMenuType } from '../../../api/apiTypes/shopApiTypes';
import { updateFoodMenu, getDailyMenus, uploadImage, getUploadImage } from '../../../api/shopApi';

const dailyMenusKey = 'daily-menus';
const getUploadImageKey = 'get-upload-image';
const SAND_BOX_PORTAL_ID = 7;

export const useDailyMenus = (
    monday = startOfWeek(new Date(), { weekStartsOn: 1 }),
    canteenId: string,
): QueryResult<DailyMenuType[], string> => {
    return useQuery(
        [dailyMenusKey, monday, canteenId],
        async () => {
            const friday = addDays(monday, 4);

            const { data: menus } = await getDailyMenus(monday, friday, canteenId);

            if (!menus.length) {
                return Array.from({ length: 5 }, (_, i) => ({
                    title: '',
                    description: '',
                    date: addDays(monday, i + 1)
                        .toISOString()
                        .split('T')[0],
                    allergens: [],
                    portalId: SAND_BOX_PORTAL_ID,
                }));
            }

            return menus;
        },
        {
            staleTime: 1000 * 60 * 60,
            enabled: !!canteenId,
        },
    );
};

export const useUpdateDailyMenu = (): MutationResultPair<
    string,
    Error | AxiosError<string>,
    { menus: DailyMenuUpdateType[]; canteenId: string },
    never
> => {
    const cache = useQueryCache();
    return useMutation<string, Error | AxiosError<string>, { menus: DailyMenuUpdateType[]; canteenId: string }, never>(
        async ({ menus, canteenId }) => {
            await updateFoodMenu(menus, canteenId);
            return canteenId;
        },
        {
            onSuccess: async (canteenId) => {
                await cache?.invalidateQueries(dailyMenusKey);
            },
        },
    );
};

export const useUploadDailyMenuImage = (
    canteenId: string,
): MutationResultPair<AxiosResponse<ImageModel[]>, AxiosError<string>, FormData, never> => {
    const cache = useQueryCache();

    return useMutation((formData: FormData) => uploadImage(formData, canteenId), {
        onSuccess: async () => {
            await cache?.invalidateQueries(getUploadImageKey);
        },
    });
};

export const useGetUploadDailyMenuImage = (canteenId: string): QueryResult<string, AxiosError> => {
    return useQuery<string, AxiosError>(
        [getUploadImageKey, canteenId],
        async () => {
            const response: AxiosResponse<string> = await getUploadImage(canteenId);
            return response.data;
        },
        {
            enabled: !!canteenId,
        },
    );
};
