import { AxiosError, AxiosResponse } from 'axios';
import type { InfiniteQueryResult, MutationResultPair, QueryResult } from 'react-query';
import { useInfiniteQuery, useMutation, useQuery, useQueryCache } from 'react-query';
import { GenerateQRCodeData } from '../../api/apiTypes/portalApiTypes';
import {
    createGuestRegistrationKey,
    deleteGuestRegistrationKey,
    generateQRCode,
    getCompaniesListForStaticQRCode,
    getCompaniesListForDynamicQRCode,
    getCompanyUsersListForStaticQRCode,
    getCompanyUsersListForDynamicQRCode,
    getGuestRegistrationKey,
    postRegisterAnonymousUserFromStaticQRCode,
    postRegisterAnonymousUserForDynamicQRCode,
    pulseOpenDoor,
    generateLink,
    postRegisterNotPortalGuest,
} from '../../api/portalApi';
import { AnonymousUserDataForDynamicQRCode, AnonymousUserDataForStaticQRCode, Companies, Employer } from './utils';

const CompaniesCacheKey = 'CompaniesCacheKey';
const CompanyUsersCacheKey = 'CompanyUsersCacheKey';
const QRCodesCacheKey = 'QRCodesCacheKey';
const GuestRegistrationKey = 'GuestRegistrationKey';
const QRCodesGenerateLink = 'QRCodesGenerateLink';
export const useGetCompanies = (
    guestRegistrationKey: string,
    encryptedTimestamp: string,
    term: string,
): InfiniteQueryResult<Companies[], AxiosError<string> | Error> => {
    const pageSize = 20;
    const data = { guestRegistrationKey, encryptedTimestamp };
    if (encryptedTimestamp) {
        return useInfiniteQuery(
            [CompaniesCacheKey, guestRegistrationKey, term],
            async (_, __, term: string, page: number) => {
                const filterParams = { pageSize, page: page ?? 0, term: term };

                return (await getCompaniesListForDynamicQRCode(data, filterParams)).data;
            },
            {
                getFetchMore: (lastPage, allPages) => {
                    if (lastPage.length !== pageSize) {
                        return false;
                    }
                    return allPages.length;
                },
                refetchInterval: 1000 * 60,
            },
        );
    }
    const dataRequest = {
        key: guestRegistrationKey,
    };
    return useInfiniteQuery(
        [CompaniesCacheKey, guestRegistrationKey, term],
        async (_, __, term: string, page: number) => {
            const filterParams = { pageSize, page: page ?? 0, term };
            return (await getCompaniesListForStaticQRCode(dataRequest, filterParams)).data;
        },
        {
            getFetchMore: (lastPage, allPages) => {
                if (lastPage.length !== pageSize) {
                    return false;
                }
                return allPages.length;
            },
            refetchInterval: 1000 * 60,
        },
    );
};

export const useGetInfiniteCompanyUsers = (
    term: string,
    guestRegistrationKey: string,
    companyKey: string,
    enabled: boolean,
    encryptedTimestamp: string,
): InfiniteQueryResult<Employer[], AxiosError<string> | Error> => {
    const pageSize = 20;
    const page = 0;
    const data = { guestRegistrationKey, companyKey, encryptedTimestamp };
    const filterParams = { pageSize, page, term };

    if (encryptedTimestamp) {
        return useInfiniteQuery<Employer[], AxiosError<string> | Error>(
            [CompanyUsersCacheKey, term],
            async (): Promise<Employer[]> => {
                const result = await getCompanyUsersListForDynamicQRCode(data, filterParams);
                return result.data;
            },
            {
                getFetchMore: (lastPage, allPages) => {
                    if (lastPage.length !== pageSize) {
                        return false;
                    }
                    return allPages.length;
                },
                enabled: enabled,
            },
        );
    }
    return useInfiniteQuery<Employer[], AxiosError<string> | Error>(
        [CompanyUsersCacheKey, term],
        async (): Promise<Employer[]> => {
            const result = await getCompanyUsersListForStaticQRCode(
                { ...data, key: data.guestRegistrationKey },
                filterParams,
            );
            return result.data;
        },
        {
            getFetchMore: (lastPage, allPages) => {
                if (lastPage.length !== pageSize) {
                    return false;
                }
                return allPages.length;
            },
            enabled: enabled,
        },
    );
};

export const useCreateAnonymousUserFromDynamicQRCode = (): MutationResultPair<
    AxiosResponse<string>,
    string | AxiosError<string> | Error,
    AnonymousUserDataForDynamicQRCode,
    never
> => {
    return useMutation(postRegisterAnonymousUserForDynamicQRCode);
};

export const useCreateAnonymousUserFromStaticQRCode = (): MutationResultPair<
    AxiosResponse<string>,
    string | AxiosError<string> | Error,
    AnonymousUserDataForStaticQRCode,
    never
> => {
    return useMutation(postRegisterAnonymousUserFromStaticQRCode);
};

export const useGetGuestRegistrationKey = (): QueryResult<string, string | AxiosError<string | Error>> => {
    return useQuery(GuestRegistrationKey, async () => (await getGuestRegistrationKey()).data);
};

export const useCreateGuestRegistrationKey = (): MutationResultPair<
    AxiosResponse<string>,
    string | AxiosError<string> | Error,
    string,
    never
> => {
    const cache = useQueryCache();
    return useMutation(createGuestRegistrationKey, {
        onSuccess: () => {
            cache.invalidateQueries([GuestRegistrationKey], { exact: true });
        },
    });
};

export const useDeleteGuestRegistrationKey = (): MutationResultPair<
    AxiosResponse<string>,
    Error | AxiosError<string>,
    string,
    never
> => {
    const cache = useQueryCache();
    return useMutation(deleteGuestRegistrationKey, {
        onSuccess: () => {
            cache.invalidateQueries([GuestRegistrationKey], { exact: true });
        },
    });
};

export const useGenerateQRCode = (
    requestData: GenerateQRCodeData,
): QueryResult<string, string | AxiosError<string | Error>> => {
    return useQuery(
        [QRCodesCacheKey],
        async () => {
            const { data } = await generateQRCode(requestData);
            return data;
        },
        { refetchInterval: 1000 * 3 * 60 },
    );
};

export const useGenerateLink = (
    requestData: GenerateQRCodeData,
): QueryResult<string, string | AxiosError<string | Error>> => {
    return useQuery(
        [QRCodesGenerateLink],
        async () => {
            const { data } = await generateLink(requestData);
            return data;
        },
        { refetchInterval: 1000 * 3 * 60 },
    );
};

export const usePulseOpenDoor = (): MutationResultPair<
    AxiosResponse<string>,
    Error | AxiosError<string>,
    string,
    never
> => useMutation(pulseOpenDoor);

export const useRegisterNotPortalGuest = (): MutationResultPair<
    AxiosResponse<string>,
    AxiosError<string> | Error,
    string,
    never
> => {
    return useMutation(postRegisterNotPortalGuest);
};
