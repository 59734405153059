import { ReactElement, useMemo, useState, useEffect, RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { Loader } from 'semantic-ui-react';
import styled from 'styled-components';
import { CaseworkerModel } from '../../../../../../../api/apiTypes/ticketingApiTypes';
import { SearchInput } from '../../../../../../../components';
import { splitName, useDebounce } from '../../../../../../../utility';
import useMouseClickedOutsideElement from '../../../../../../../utility/hooks/useMouseClickOutsideElement';
import { useUpdateTicketTypeCaseworker } from '../../../../../apiQueries/useTicketingType';
import { useGetInfiniteCaseworker } from '../../../../../apiQueries/useTicketingUser';
import CaseworkerCard from '../../../CaseworkerCard';

const CaseworkerSearchInput = styled(SearchInput)`
    margin: 0;
`;

const Container = styled.div`
    position: relative;
`;

const SearchForCaseworkerContainer = styled.div`
    z-index: 1;
    position: relative;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
`;

const AddedCaseworkerCardWrapper = styled.div`
    padding-top: 0.571rem;
    position: absolute;
    top: 0;
    width: 100%;
`;

const AddedCaseworkerCard = styled(CaseworkerCard)`
    margin: 0.571rem 0;
`;

const LoadingAndErrorContainer = styled.div`
    height: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    background-color: white;
`;

const ErrorMessage = styled.p`
    color: var(--error-color-dark);
`;

interface CaseworkerSearchProps {
    caseworkers: CaseworkerModel[] | [];
    typedId: string;
    onClickRemoveCaseworker(guid: string): void;
    type: 'remove' | 'select' | 'search';
    onStatusChange(status: string): void;
    scrollRef: RefObject<HTMLElement>;
}

const CaseworkerSearch = ({
    caseworkers,
    onClickRemoveCaseworker,
    type,
    typedId,
    onStatusChange,
    scrollRef,
}: CaseworkerSearchProps): ReactElement => {
    const [searchField, setSearchField] = useState('');
    const debouncedSearchField = useDebounce(searchField, 200);
    const { t } = useTranslation('Ticketing', { keyPrefix: 'settings' });
    useMouseClickedOutsideElement(scrollRef, () => setSearchField(''));

    const {
        data: users,
        isLoading,
        isError,
        isFetchingMore,
        canFetchMore,
        fetchMore,
    } = useGetInfiniteCaseworker(debouncedSearchField);

    const [
        updateCaseWorker,
        {
            isLoading: isLoadingAddingCaseworker,
            isSuccess: isSuccessAddingCaseworker,
            isError: isErrorAddingCaseworker,
        },
    ] = useUpdateTicketTypeCaseworker();

    useEffect(() => {
        isLoadingAddingCaseworker && onStatusChange(t('savingStatus.isSaving'));
        isSuccessAddingCaseworker && onStatusChange(t('savingStatus.changesSaved'));
        isErrorAddingCaseworker && onStatusChange(t('savingStatus.error'));
    }, [isLoadingAddingCaseworker, isSuccessAddingCaseworker, isErrorAddingCaseworker, onStatusChange]);

    const handleFetchMore = () => {
        if (!isFetchingMore) fetchMore();
    };

    const filteredUsers = useMemo(() => {
        return (
            (users &&
                users.flat()?.filter((user: any) => !caseworkers.some((caseworker) => caseworker.id === user.id))) ??
            []
        );
    }, [users, caseworkers]);

    const addCaseworkerHandler = (id: string) => {
        if (caseworkers.some((caseworker: CaseworkerModel) => caseworker.id === id)) return;
        const user: any = filteredUsers?.find((user: any) => user.id === id);

        const [firstName, lastName] = (user && splitName(user?.name)) ?? ['', ''];
        if (type === 'search' || type === 'select') {
            updateCaseWorker([
                typedId,
                { avatar: user?.avatar ?? '', id: user?.id ?? '', firstName: firstName, lastName: lastName },
            ]);
        }
        setSearchField('');
    };

    return (
        <div>
            <CaseworkerSearchInput
                placeholder={t('searchByNameOrEmail')}
                value={searchField}
                onChange={(e) => setSearchField(e)}
            />
            <Container>
                <SearchForCaseworkerContainer>
                    {isLoading || isError ? (
                        <LoadingAndErrorContainer>
                            {isLoading && <Loader active inline />}
                            {isError && <ErrorMessage>{t('staticErrorMessage')}</ErrorMessage>}
                        </LoadingAndErrorContainer>
                    ) : null}
                    <InfiniteScroll
                        threshold={0}
                        initialLoad={false}
                        hasMore={canFetchMore}
                        loadMore={handleFetchMore}
                        loader={
                            <LoadingAndErrorContainer>
                                {isFetchingMore ? <Loader active inline /> : null}
                            </LoadingAndErrorContainer>
                        }
                        useWindow={false}
                        getScrollParent={() => scrollRef?.current}
                    >
                        {filteredUsers?.map(({ id, name, email, avatar }) => {
                            return (
                                <CaseworkerCard
                                    id={id}
                                    key={id}
                                    name={name ?? email}
                                    email={email}
                                    avatar={avatar}
                                    onClick={addCaseworkerHandler}
                                    type="search"
                                />
                            );
                        })}
                    </InfiniteScroll>
                </SearchForCaseworkerContainer>
                <AddedCaseworkerCardWrapper>
                    {caseworkers.length > 0 &&
                        caseworkers.map(({ id, email, avatar, firstName, lastName }) => {
                            return (
                                <AddedCaseworkerCard
                                    id={id}
                                    key={id}
                                    name={`${firstName} ${lastName}`}
                                    email={email}
                                    avatar={avatar}
                                    onClickRemoveUser={onClickRemoveCaseworker}
                                    type={type}
                                />
                            );
                        })}
                </AddedCaseworkerCardWrapper>
            </Container>
        </div>
    );
};

export default CaseworkerSearch;
