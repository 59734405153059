import { ReactElement } from 'react';
import styled from 'styled-components';
import { UserTicketType, UserCategory } from '../../../../api/apiTypes/ticketingApiTypes';
import { SvgIcon } from '../../../../components';
import useNewTicketSteps from '../UseNewTicketSteps';

interface NewTicketListProps {
    items: UserTicketType[] | UserCategory[];
    onclick(e: string): void;
    isType?: boolean;
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const TypeList = styled.ul`
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    overflow-y: auto;
    margin: 0;
`;
const TypeItem = styled.li`
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 1px solid #d4d6d7;
    width: 100%;
    padding-top: 1rem;
    padding-right: 2.1rem;
    padding-bottom: 1rem;
    position: relative;
    cursor: pointer;
    min-height: 85px;
    word-wrap: break-word;

    :first-child {
        margin-top: 0.1rem;
    }

    :last-child {
        margin-bottom: 0.1rem;
        border-bottom: none;
    }

    :focus-visible {
        outline: 1px solid var(--primary-color);
    }

    :focus {
        outline: 1px solid var(--primary-color);
    }

    :focus:not(:focus-visible) {
        outline: none;
    }
`;

const TypeItemHeader = styled.h2`
    font-weight: 900;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.2px;
    color: var(--primary-color);
    pointer-events: none;
    margin-bottom: 0;
`;

const TypeItemDesc = styled.p`
    font-size: 16px;
    line-height: 24px;
    pointer-events: none;
    margin-top: 1rem;
`;

const NextStepIcon = styled.div`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    pointer-events: none;
`;

export const NewTicketList = ({ items, onclick }: NewTicketListProps): ReactElement => {
    const stepHook = useNewTicketSteps();

    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
        onclick((e.target as HTMLLIElement).id);
        stepHook.goNext();
    };

    const handleKeyPress = (e: React.KeyboardEvent<HTMLElement>) => {
        onclick((e.target as HTMLLIElement).id);
        stepHook.goNext();
    };

    return (
        <Container>
            <TypeList>
                {items.map((item, index) => {
                    return (
                        <TypeItem
                            tabIndex={0}
                            key={index}
                            id={item.id}
                            onClick={(e: React.MouseEvent<HTMLElement>) => handleClick(e)}
                            onKeyPress={(e: React.KeyboardEvent<HTMLElement>) => handleKeyPress(e)}
                        >
                            <TypeItemHeader> {item.name}</TypeItemHeader>

                            <NextStepIcon>
                                <SvgIcon name="ArrowPrimary" />
                            </NextStepIcon>

                            {item.externalDescription && <TypeItemDesc>{item.externalDescription}</TypeItemDesc>}
                        </TypeItem>
                    );
                })}
            </TypeList>
        </Container>
    );
};
