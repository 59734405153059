import { useQuery } from 'react-query';
import type { QueryResult } from 'react-query';
import { SimpleCanteen, Vendor } from '../../../api/apiTypes/foodForMeetingsApiTypes';
import { CanteenWithAvailabilityParams, DeliveryLocationOptionFilters } from '../../../api/apiTypes/shopApiTypes';
import { getBuildingsWithAvailableMeetingRooms } from '../../../api/bookingApi';
import { getAllAvailableCanteens, getSimpleCanteens } from '../../../api/foodForMeetingsApi';
import { DeliveryLocationOptions } from '../contexts/types';

export const CanteensCacheKey = 'CanteensCacheKey';
const SimpleCanteensCacheKey = 'SimpleCanteensCacheKey';
const DeliveryLocationOptionsCacheKey = 'DeliveryLocationOptionsCacheKey';

export const useGetAllAvailableCanteens = (
    { suggestedCanteenIds, isDelivery, orderDateTime, menuType }: CanteenWithAvailabilityParams,
    enabled?: boolean,
): QueryResult<Vendor[]> => {
    return useQuery(
        [CanteensCacheKey, suggestedCanteenIds, isDelivery, orderDateTime, menuType],
        async () => (await getAllAvailableCanteens({ suggestedCanteenIds, isDelivery, orderDateTime, menuType }))?.data,
        {
            enabled,
        },
    );
};

export const useGetSimpleCanteens = (): QueryResult<SimpleCanteen[]> =>
    useQuery([SimpleCanteensCacheKey], async () => (await getSimpleCanteens())?.data);

export const useGetDeliveryLocationOptions = (
    filters: DeliveryLocationOptionFilters,
    enabled?: boolean,
): QueryResult<DeliveryLocationOptions[] | [], string> => {
    return useQuery(
        [DeliveryLocationOptionsCacheKey, filters],
        async () => {
            const response = await getBuildingsWithAvailableMeetingRooms(filters);
            const buildings = response.data;
            if (typeof buildings === 'string' || !buildings) return [];

            return (
                buildings
                    ?.map((building) => {
                        return building.rooms.map((room) => {
                            return {
                                id: room.id,
                                value: room.name,
                            };
                        });
                    })
                    .flat() ?? []
            );
        },
        { staleTime: 1000 * 60, enabled },
    );
};
