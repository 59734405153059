import React, { memo, ReactElement, useCallback, useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import { Loader } from 'semantic-ui-react';
import styled from 'styled-components';
import * as yup from 'yup';
import { DailyMenuType } from '../../../../../api/apiTypes/shopApiTypes';
import LoaderContainer from '../../../../../common/Loader/LoaderContainer';
import { ErrorMessage } from '../../../../../components';
import { FlexBox } from '../../../../../components/styleds';
import { useGetProductsAllergens } from '../../../../ShopAtWork/apiQueries/useShopProducts';
import { useDailyMenus, useUpdateDailyMenu } from '../../useDailyMenu';
import DayMenuInput from './DayMenuInput';

const ContainerWrapper = styled.form`
    display: grid;
    grid-gap: 1.71rem;
    width: 100%;
    font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
    margin-top: 1.71rem;
    position: relative;
`;

const TITLE_MAX_LENGTH = 255;

const schema = (tFunction: TFunction) =>
    yup.object().shape({
        menus: yup.array().of(
            yup.object().shape({
                title: yup.string().max(TITLE_MAX_LENGTH, tFunction('titleMaxLengthError')),
            }),
        ),
    });

interface MenuFormProps {
    monday: Date;
    formId: string;
    setIsSaving(isSaving: boolean): void;
    setFormIsDirty(isDirty: boolean): void;
    setIsSuccess(isSuccess: boolean): void;
    setIsError(isError: boolean): void;
    showNavDisabledError: boolean;
    selectedCanteenIds: string;
    isCanteensError: boolean;
    isLoadingCanteens: boolean;
}

export interface CreateMenuForm {
    canteen: string;
    menus: DailyMenuType[];
}

const MenuForm = ({
    monday,
    formId,
    setIsSaving,
    setIsSuccess,
    setFormIsDirty,
    setIsError,
    showNavDisabledError,
    selectedCanteenIds,
    isCanteensError,
    isLoadingCanteens,
}: MenuFormProps): ReactElement => {
    const { t } = useTranslation('Dashboard', { keyPrefix: 'dailyMenu' });
    const { data: allAllergens, isLoading: isAllergensLoading } = useGetProductsAllergens();
    const [createFoodMenu, { isLoading: isSaving, isError, isSuccess }] = useUpdateDailyMenu();

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
        watch,
        reset,
        control,
    } = useForm<CreateMenuForm>({
        resolver: yupResolver(schema(t)),
        mode: 'onChange',
        defaultValues: {
            menus: [],
        },
    });

    const {
        data: weeksMenu,
        isLoading: isLoadingMenu,
        isError: isMenusError,
    } = useDailyMenus(monday, selectedCanteenIds);

    useEffect(() => {
        // Reset form when weeksMenu data changes
        if (weeksMenu?.length && !isLoadingMenu && selectedCanteenIds) {
            reset({
                menus: weeksMenu,
            });
        }
    }, [weeksMenu, reset, isLoadingMenu, selectedCanteenIds]);

    const onSubmit = useCallback(
        ({ menus }: CreateMenuForm) => {
            const updatedMenusList = menus.filter(Boolean).map((el) => ({
                ...el,
                allergens: el.allergens?.length ? el.allergens.map((allergen) => allergen.id) : [],
            }));

            createFoodMenu({ menus: updatedMenusList, canteenId: selectedCanteenIds });
            reset({ menus });
        },
        [reset, selectedCanteenIds],
    );

    useEffect(() => {
        setIsSaving(isSaving);
    }, [isSaving, setIsSaving]);

    useEffect(() => {
        setFormIsDirty(isDirty);
    }, [isDirty, setFormIsDirty]);

    useEffect(() => {
        setIsSuccess(isSuccess);
    }, [isSuccess, setIsSuccess]);

    useEffect(() => {
        setIsError(!!Object.keys(errors).length);
    }, [errors, setIsError]);

    const menuFormInputs = useMemo(() => {
        if (!isLoadingMenu) {
            return watch('menus')?.map((menu, i) => (
                <DayMenuInput
                    key={menu.date}
                    index={i}
                    register={register}
                    control={control}
                    allergens={isAllergensLoading ? [] : allAllergens ?? []}
                    error={errors?.menus?.[i]}
                    menu={menu}
                />
            ));
        } else return null;
    }, [watch, register, control, isAllergensLoading, allAllergens, errors, isLoadingMenu]);

    const isLoaderExisting = useMemo(
        () => isLoadingMenu || isLoadingCanteens || isSaving,
        [isLoadingMenu, isLoadingCanteens, isSaving],
    );

    return (
        <ContainerWrapper id={formId} onSubmit={handleSubmit(onSubmit)}>
            {isLoaderExisting && (
                <FlexBox align={'center'} justify={'center'} height={'100%'} position={'absolute'}>
                    <LoaderContainer>
                        <Loader active inline>
                            {t('loadingMenuTextLoader')}
                        </Loader>
                    </LoaderContainer>
                </FlexBox>
            )}
            {(isMenusError || isCanteensError) && <ErrorMessage message={t('errorLoadingMenuMessage')} />}
            {showNavDisabledError && <ErrorMessage message={t('errorMessageForUnsavedChanges')} />}
            <p>{t('menuFormTitle')}</p>
            {isError && <ErrorMessage message={t('errorMessageWhileSavingTheMenu')} />}
            <FlexBox dir={'column'}>{menuFormInputs}</FlexBox>
        </ContainerWrapper>
    );
};

export default memo(MenuForm);
