import { AxiosError } from 'axios';
import { QueryResult, useQuery } from 'react-query';
import { User } from '../../../api/apiTypes/portalApiTypes';
import { getUserForCompanyInfo } from '../../../api/portalApi';
import useCurrentUserContext from '../../Contexts/useCurrentUserContext';

export const useGetUserByIdInfoCacheKey = 'useGetUserByIdInfoCacheKey';

export const useGetUserByIdInfo = (): QueryResult<User, AxiosError<string> | Error> => {
    const { state: userInfo } = useCurrentUserContext();
    return useQuery(
        [useGetUserByIdInfoCacheKey],
        async () => {
            const { data } = await getUserForCompanyInfo(
                userInfo.currentUser?.companyId ?? '',
                userInfo.currentUser?.id ?? '',
            );
            return data;
        },
        { staleTime: 1000 * 60 * 60 * 24 },
    );
};
